import React from "react";
import Button from "@mui/material/Button";
import {
  Backdrop,
  CircularProgress,
  Alert,
  Collapse,
  Card,
  CardActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TextField,  
  TableCell,
  Paper,
  TableBody,
  IconButton,
} from "@mui/material";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CloseIcon from '@mui/icons-material/Close';

import { Balance, FindBalances, CalculateInterest, interestError } from '../services/ledgerService'
import { textChangeRangeIsUnchanged } from "typescript";

export default class BalanceTable extends React.Component {
  state: {
    loading: boolean;
    errorMessage: string;
    errorOpen: boolean;
    balances: Balance[],
    cashRate: number,
    providerFee: number,
    adminFee: number,
    timeTill: any,
    businessId: string
  } = {
    loading: false,
    errorMessage: "",
    errorOpen: false,
    balances: [],
    cashRate: 0,
    providerFee: 0,
    adminFee: 0,
    timeTill: "",
    businessId: "",
  };

  constructor(props:any) {
    super(props);
    this.refreshBalances();
  }

  refreshBalances = async () => {
    this.setState({
      loading: true,
      balances: [],
    })
    const balances = await FindBalances(this.state.businessId)

    this.setState({
      balances,
      loading: false,
    })
  }

  calculateInterest = async () => {
    this.setState({
      loading: true,
    })

    console.log(this.state.cashRate, this.state.adminFee, this.state.providerFee, this.state.timeTill, !this.state.timeTill.length)
    if (!this.state.cashRate || this.state.cashRate <= 0 || this.state.providerFee < 0 || this.state.adminFee < 0 || !this.state.timeTill) {
      this.setState({
        errorMessage: "Please include valid rates and a date in the future since last calculation.",
        errorOpen: true,
        loading: false,
      });
      return;
    }

    const result = await CalculateInterest(this.state.cashRate, this.state.providerFee, this.state.adminFee, this.state.timeTill.format("YYYY-MM-DDTHH:mm:ssZ"))
    switch (result.error) {
      case interestError.NONE:
        await this.refreshBalances();
        return;
      case interestError.CONFLICT:
        this.setState({
          errorMessage: "Date provided is in the past since latest interest calculation. Please provide one further in the future.",
          errorOpen: true,
          loading: false,
        });
        return;
      case interestError.INVALID_INPUT:
        this.setState({
          errorMessage: "Invalid input provided please adjust rates and or date.",
          errorOpen: true,
          loading: false,
        });
        return;
      case interestError.SERVER_ERROR:
        this.setState({
          errorMessage: "PANIC Something went wrong pin the blame on someone.",
          errorOpen: true,
          loading: false,
        });
        return;
    }
  }

  handleCashRateChange = async (event: any) => {
    this.setState({
      cashRate: parseFloat(event.target.value),
    })
  }

  handleProviderFeeChange  = async (event: any) => {
    this.setState({
      providerFee: parseFloat(event.target.value),
    })
  }

  handleAdminFeeChange  = async (event: any) => {
    this.setState({
      adminFee: parseFloat(event.target.value),
    })
  }

  setTimeTill = async (date:any) => {
    this.setState({
      timeTill: date,
    })
  }

  setBusinessId = async (id: any) => {
    this.setState({
      businessId: id.target.value,
    })
  }

  setErrorShown = async (state:boolean) => {
    this.setState({
        errorOpen: state,
    })
  }

  render() {
    return (
      <div>
        <TableContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Collapse in={this.state.errorOpen}>
        <Alert
            severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                this.setErrorShown(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
            {this.state.errorMessage}
        </Alert>
        </Collapse>
        <Card variant="outlined">
          <CardActions>
              <TextField
              id="business-id-balance"
              label="Filter by Business ID"
              variant="outlined"
              value={this.state.businessId}
              onChange={(newValue) => this.setBusinessId(newValue)}
            ></TextField>
          <Button onClick={this.refreshBalances}>REFRESH BALANCES</Button>
              <TextField
              id="cash-rate"
              label="Cash rate"
              type="number"
              variant="outlined"
              value={this.state.cashRate}
              onChange={this.handleCashRateChange}
            ></TextField>
              <TextField
              id="provider-fee"
              label="Provider fee"
              type="number"
              variant="outlined"
              value={this.state.providerFee}
              onChange={this.handleProviderFeeChange}
            ></TextField>
              <TextField
              id="admin-fee"
              label="Admin fee"
              type="number"
              variant="outlined"
              value={this.state.adminFee}
              onChange={this.handleAdminFeeChange}
            ></TextField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                  label="Calculate till"
                  value={this.state.timeTill}
                  onChange={(newValue) => this.setTimeTill(newValue)}
                />
              </LocalizationProvider>
              <Button onClick={this.calculateInterest}>Calculate Interest</Button>
          </CardActions>
            </Card>
          <Table sx={{ minWidth: 690 }}>
            <TableHead>
              <TableCell>Business ID</TableCell>
              <TableCell>Last Calculated</TableCell>
              <TableCell>Available Balance</TableCell>
              <TableCell>Interest Earned</TableCell>
              <TableCell>Version</TableCell>
            </TableHead>
            <TableBody>
              {this.state.balances.map((balance) => (
                <TableRow key={`${balance.BusinessId}-${balance.Version}`} sx={{'&:last-child td, &:last-child th': { border: 0 }}}>
                  <TableCell>
                    {balance.BusinessId}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {balance.Timestamp.toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {balance.AvailableBalance}
                  </TableCell>
                  <TableCell>
                    {balance.InterestEarned}
                  </TableCell>
                  <TableCell>
                    {balance.Version}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
