import React from 'react';
import logo from './logo.svg';
import Button from '@mui/material/Button';
import './App.css';
import BalanceTable from './balance/balanceTable';
import { Stack, Paper } from '@mui/material';
import DepositService from './deposit/deposit';
import { styled } from '@mui/material/styles';
import Verifier from './verification/verification';
import WithdrawalService from './withdrawal/withdrawal';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Stack spacing={2}>
          <Item>
            {/* <Verifier></Verifier> */}
            <h2>PARPERA EARN SIMULATION</h2>
            <p>Note: this is set-up against dev which is a shared environment. Calculating interest calculates across all businesses.</p>
          </Item>
          <Item>
            <DepositService></DepositService>
          </Item>
          <Item>
            <WithdrawalService></WithdrawalService>
          </Item>
          <Item>
            <BalanceTable></BalanceTable>
          </Item>
        </Stack>
      </header>
    </div>
  );
}

export default App;
