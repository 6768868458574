const baseUrl =
  "https://vrcz83w90c.execute-api.ap-southeast-2.amazonaws.com/test1";

export interface Deposit {
  id: string;
  businessId: string;
  status: string;
  timestamp: Date;
  amount: number;
}

export enum DepositStatus {}

export async function CreateDeposit(businessId: string, amount: number) {
  const payload = {
    businessId,
    amount: {
      value: amount,
      currency: "AUD",
    },
    walletId: "walletId",
  };
  const res = await fetch(`${baseUrl}/deposit`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });
  const response = await res.json();

  return mapDeposit(response["deposit"]);
}

export async function FindDeposits(businessId: string): Promise<Deposit[]> {
  const payload = {
    businessId,
    status: "INITIATED",
  };

  const res = await fetch(`${baseUrl}/deposit/find`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });
  const response = await res.json();
  if (!response.deposits) {
    return [];
  }

  return response.deposits.map(mapDeposit);
}

function mapDeposit(deposit: any): Deposit {
  return {
    id: deposit["id"],
    businessId: deposit["businessId"],
    status: deposit["status"],
    timestamp: mapTimestamp(deposit["timestamp"]),
    amount: deposit["amount"]["value"],
  };
}

export interface Withdrawal {
  id: string;
  businessId: string;
  status: string;
  timestamp: Date;
  amount: number;
}

export enum createWithdrawalError {
  NONE,
  INVALID_INPUT,
}

export interface CreateWithdrawalResponse {
  withdrawal?: Withdrawal;
  error?: createWithdrawalError;
}

export async function CreateWithdrawal(
  businessId: string,
  amount: number
): Promise<CreateWithdrawalResponse> {
  const payload = {
    businessId,
    amount: {
      value: amount,
      currency: "AUD",
    },
    walletId: "walletId",
  };
  const res = await fetch(`${baseUrl}/withdrawal`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });
  switch (res.status) {
    case 200:
      const response = await res.json();

      return {
        withdrawal: mapWithdrawal(response["withdrawal"]),
        error: createWithdrawalError.NONE,
      };
    default:
      return {
        error: createWithdrawalError.INVALID_INPUT,
      };
  }
}

export async function FindWithdrawals(
  businessId: string
): Promise<Withdrawal[]> {
  const payload = {
    businessId,
    status: "INITIATED",
  };

  const res = await fetch(`${baseUrl}/withdrawal/find`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });
  const response = await res.json();
  if (!response.withdrawals) {
    return [];
  }

  return response.withdrawals.map(mapWithdrawal);
}

function mapWithdrawal(withdrawal: any): Withdrawal {
  return {
    id: withdrawal["id"],
    businessId: withdrawal["businessId"],
    status: withdrawal["status"],
    timestamp: mapTimestamp(withdrawal["timestamp"]),
    amount: withdrawal["amount"]["value"],
  };
}

export interface Balance {
  BusinessId: string;
  Timestamp: Date;
  AvailableBalance: string;
  InterestEarned: string;
  Version: number;
}

export async function FindBalances(
  businessId?: string,
  continuation?: string
): Promise<Balance[]> {
  const payload = {
    continuation,
    businessId,
  };

  const res = await fetch(`${baseUrl}/balance/find`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });

  const response = await res.json();

  console.log("our raw balances: ", response);

  const balances = response.balances.map((balance: any) => {
    return {
      BusinessId: balance["BusinessId"],
      Timestamp: mapTimestamp(balance["Timestamp"]),
      AvailableBalance: balance["AvailableBalance"]["value"],
      InterestEarned: balance["InterestEarned"]["value"],
      Version: balance["Version"],
    };
  });

  balances.sort((a: Balance, b: Balance) => {
    return a.Version < b.Version;
  });

  return balances;
}

export interface Verification {
  type: string;
  status: string;
  id: string;
  withdrawals: [];
  deposits: [];
  expectedAmount: number;
  timestamp: Date;
}

export enum VerificationType {
  DEPOSIT = "DEPOSIT",
  WITHDRAWAL = "WITHDRAWAL",
}

export async function CreateVerification(type: VerificationType) {
  const payload = {
    verificationType: type,
  };

  const res = await fetch(`${baseUrl}/verification`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });

  const response = await res.json();
  return mapVerification(response["verification"]);
}

export async function FindVerifications() {
  const payload = {
    type: "DEPOSIT",
  };
  const res = await fetch(`${baseUrl}/verification/find`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });
  const response = await res.json();
  if (!response.verifications) {
    return [];
  }
  return response.verifications.map(mapVerification);
}

export async function VerifyVerification(id: string, amountFound: number) {
  const payload = {
    verificationId: id,
    amountFound: {
      currency: "AUD",
      value: amountFound,
    },
  };
  const res = await fetch(`${baseUrl}/verification/verify`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });
}

export enum interestError {
  NONE,
  CONFLICT,
  INVALID_INPUT,
  SERVER_ERROR,
}

export interface calculateInterestResponse {
  error: interestError;
}

export async function CalculateInterest(
  cashRate: number,
  providerFee: number,
  adminFee: number,
  tillTime: string
): Promise<calculateInterestResponse> {
  const payload = {
    cashRate,
    administrationFee: adminFee,
    providerFee: providerFee,
    tillTime,
  };
  const res = await fetch(`${baseUrl}/interest/calculate`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });
  switch (res.status) {
    case 200:
      const response = await res.json();
      console.log("our calculated interest ", response);
      return {
        error: interestError.NONE,
      };
    case 409:
      return {
        error: interestError.CONFLICT,
      };
    case 400:
      return {
        error: interestError.INVALID_INPUT,
      };
    default:
      return {
        error: interestError.SERVER_ERROR,
      };
  }
}

function mapVerification(response: any): Verification {
  return {
    type: response["Type"],
    status: response["Status"],
    id: response["VerificationId"],
    withdrawals: response["WithdrawalIds"] ? response["WithdrawalIds"] : [],
    deposits: response["DepositIds"] ? response["DepositIds"] : [],
    timestamp: mapTimestamp(response["Timestamp"]),
    expectedAmount: response["ExpectedAmount"]
      ? response["ExpectedAmount"]["Value"]
      : 0,
  };
}

function mapTimestamp(timestamp: string): Date {
  // Backend returns unix milli
  return new Date(Math.floor(parseInt(timestamp, 10) / 1000));
}
