import {
    Box,
    Card,
    Collapse,
    Backdrop,
    CircularProgress,
    CardContent,
    CardActions,
    Button,
    TextField,
    Table,
    Paper,
    Alert,
    IconButton,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
  } from "@mui/material";
  import CloseIcon from '@mui/icons-material/Close';
  import React from "react";
  import {
    Withdrawal,
    VerificationType,
    CreateWithdrawal,
    FindWithdrawals,
    CreateVerification,
    VerifyVerification,
  } from "../services/ledgerService";
  
  export default class WithdrawalService extends React.Component {
    state: {
      withdrawals: Withdrawal[];
      businessId: string;
      loading: boolean;
      errorMessage: string;
      errorOpen: boolean;
      amount: number;
    } = {
      withdrawals: [],
      businessId: "",
      errorMessage: "",
      errorOpen: false,
      loading: false,
      amount: 0,
    };
  
    refreshWithdrawals = async () => {
      this.setState({
          loading: true,
      });
      const withdrawals = await FindWithdrawals(this.state.businessId);
      console.log("our withdrawals", withdrawals);
      this.setState({
        withdrawals,
        loading: false,
      });
    };
  
    createAndVerifyWithdrawal = async () => {
      this.setState({
          loading: true,
      });
      if (!this.state.businessId || !this.state.amount || this.state.amount <= 0) {
          this.setState({
              errorMessage: "Please include a business ID and valid amount.",
              errorOpen: true,
              loading: false,
          });
          return;
      }
  
      const withdrawal = await CreateWithdrawal(
        this.state.businessId,
        this.state.amount
      );
      if (withdrawal.error) {
          this.setState({
              errorMessage: "Invalid withdrawal amount.",
              errorOpen: true,
              loading: false,
          });
          return;
      }
      console.log("our withdrawal: ", withdrawal);
  
      const verification = await CreateVerification(VerificationType.WITHDRAWAL);

      console.log("our verification: ", verification);
  
      const verifyRes = await VerifyVerification(
        verification.id,
        this.state.amount
      );
      console.log("our verify res: ", verifyRes);
      await this.refreshWithdrawals();
    };
  
    setAmount = async (event: any) => {
      this.setState({
        amount: parseFloat(event.target.value),
      });
    };
  
    handleInputChange = async (event: any) => {
      this.setState({
        businessId: event.target.value,
      });
    };
  
    setErrorShown = async (state:boolean) => {
      this.setState({
          errorOpen: state,
      })
    }
  
    render(): React.ReactNode {
      return (
        <TableContainer>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Collapse in={this.state.errorOpen}>
          <Alert
              severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  this.setErrorShown(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
              {this.state.errorMessage}
          </Alert>
          </Collapse>
          <Card variant="outlined">
            <CardActions>
              <TextField
                id="business-id"
                label="Business Id"
                variant="outlined"
                value={this.state.businessId}
                onChange={this.handleInputChange}
              ></TextField>
              <TextField
                id="withdrawal-amount"
                label="Withdrawal amount AUD"
                variant="outlined"
                type="number"
                value={this.state.amount}
                onChange={this.setAmount}
              ></TextField>
              <Button onClick={this.refreshWithdrawals}>Refresh withdrawals</Button>
              <Button onClick={this.createAndVerifyWithdrawal}>
                Create and verify Withdrawal
              </Button>
            </CardActions>
          </Card>
          <Table sx={{ minWidth: 690 }}>
            <TableHead>
              <TableCell>ID</TableCell>
              <TableCell>BusinessId</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>amount</TableCell>
            </TableHead>
            <TableBody>
              {this.state.withdrawals.map((withdrawal) => (
                <TableRow
                  key={withdrawal.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{withdrawal.id}</TableCell>
                  <TableCell>{withdrawal.businessId}</TableCell>
                  <TableCell>{withdrawal.status}</TableCell>
                  <TableCell>{withdrawal.timestamp.toLocaleString()}</TableCell>
                  <TableCell>{withdrawal.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }
  