import {
  Box,
  Card,
  Collapse,
  Backdrop,
  CircularProgress,
  CardContent,
  CardActions,
  Button,
  TextField,
  Table,
  Paper,
  Alert,
  IconButton,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import {
  Deposit,
  VerificationType,
  CreateDeposit,
  CreateVerification,
  FindDeposits,
  VerifyVerification,
} from "../services/ledgerService";

export default class DepositService extends React.Component {
  state: {
    deposits: Deposit[];
    businessId: string;
    loading: boolean;
    errorMessage: string;
    errorOpen: boolean;
    amount: number;
  } = {
    deposits: [],
    businessId: "",
    errorMessage: "",
    errorOpen: false,
    loading: false,
    amount: 0,
  };

  refreshDeposits = async () => {
    this.setState({
        loading: true,
    });
    const deposits = await FindDeposits(this.state.businessId);
    console.log("our deposits", deposits);
    this.setState({
      deposits,
      loading: false,
    });
  };

  createAndVerifyDeposit = async () => {
    this.setState({
        loading: true,
    });
    if (!this.state.businessId || !this.state.amount || this.state.amount <= 0) {
        this.setState({
            errorMessage: "Please include a business ID and valid amount.",
            errorOpen: true,
            loading: false,
        });
        return;
    }

    const deposit = await CreateDeposit(
      this.state.businessId,
      this.state.amount
    );
    console.log("our deposit: ", deposit);

    const verification = await CreateVerification(VerificationType.DEPOSIT);
    console.log("our verification: ", verification);

    const verifyRes = await VerifyVerification(
      verification.id,
      this.state.amount
    );
    console.log("our verify res: ", verifyRes);
    await this.refreshDeposits();
  };

  setAmount = async (event: any) => {
    this.setState({
      amount: parseFloat(event.target.value),
    });
  };

  handleInputChange = async (event: any) => {
    this.setState({
      businessId: event.target.value,
    });
  };

  setErrorShown = async (state:boolean) => {
    this.setState({
        errorOpen: state,
    })
  }

  render(): React.ReactNode {
    return (
      <TableContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Collapse in={this.state.errorOpen}>
        <Alert
            severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                this.setErrorShown(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
            {this.state.errorMessage}
        </Alert>
        </Collapse>
        <Card variant="outlined">
          <CardActions>
            <TextField
              id="business-id"
              label="Business Id"
              variant="outlined"
              value={this.state.businessId}
              onChange={this.handleInputChange}
            ></TextField>
            <TextField
              id="deposit-amount"
              label="Deposit amount AUD"
              variant="outlined"
              type="number"
              value={this.state.amount}
              onChange={this.setAmount}
            ></TextField>
            <Button onClick={this.refreshDeposits}>Refresh deposits</Button>
            <Button onClick={this.createAndVerifyDeposit}>
              Create and verify Deposit
            </Button>
          </CardActions>
        </Card>
        <Table sx={{ minWidth: 690 }}>
          <TableHead>
            <TableCell>ID</TableCell>
            <TableCell>BusinessId</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell>amount</TableCell>
          </TableHead>
          <TableBody>
            {this.state.deposits.map((deposit) => (
              <TableRow
                key={deposit.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{deposit.id}</TableCell>
                <TableCell>{deposit.businessId}</TableCell>
                <TableCell>{deposit.status}</TableCell>
                <TableCell>{deposit.timestamp.toLocaleString()}</TableCell>
                <TableCell>{deposit.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
